$layout-selector: ".vtt-gen-layout ";
$sidebar-width: 250px;

$primary-color: #6244BB;
; // primary color for all components 
$primary-color-light: lighten($primary-color, 40%); // 
$link-color: #6244BB; // link color
$success-color: #0d9c5e; // success state color
$warning-color: #faad14; // warning state color 
$error-color: #f5222d; // error state color

/* Sec solors*/

$lightBlue: #A8C4E4; // primary color for all components 
$darkBlue: #2B4766; // primary color for all components 

$text-color:#1A1A1A; // major text color
$light-text: #4D6876;


/* Custom styles */

$lightBorderColor : #D8D8D8;
$greyBorder : #D8D8D8;
$lightBgColor : #F9F9F9;


/* color Var */

$greyGray9 : #262626;
$greyGray8 : #595959;
$greyGray7 : #8c8c8c;
$greyGray5 : #f7f7f7;



// common Styles

#{$layout-selector}{
  @media only screen and (min-width: 770px) {
    padding-left: $sidebar-width;
  }
  &.mobile-menu-opened{
    .ant-layout-sider{
      left: 0;
    }
    .mobile-menu-button{
      background: $primary-color;
      .anticon-menu{
        display: none;
      }
      .anticon-close{
        display: block;
        color: #fff;
      }
    }
    .ant-layout-header {
      .vtt-logo{
        display: none;
      }
    }
    .vtt-fader{
      display: block;
    }
  }
  .dashboard-button{
    display: block;
  }
  .vtt-fader{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.351);
    z-index: 10;
    display: none;
  }
  .mobile-only{
    display: block !important;
    @media only screen and (min-width: 770px) {
      display: none !important;
    }
  }
  .ant-layout-sider{
    position: fixed;
    width: $sidebar-width !important;
    max-width: $sidebar-width !important;
    height: 100vh;
    left: -400px;
    top: 0;
    padding: 65px 0 0 0;
    z-index: 101;
    box-shadow: none;
    border-right: 1px solid $lightBorderColor;
    @media only screen and (min-width: 770px) {
      left: 0;
      box-shadow: inset 0px 0px 12px rgba(0, 0, 0, 0.187);
    }
  }
  .ant-layout-header{
    color: $primary-color;
    padding-left: 50px;
    z-index: 999;
    .ant-space{
      display: flex;
    }
    .button-section{
      display: none;
      @media only screen and (min-width: 770px) {
        display: block;
      }
    }
    @media only screen and (min-width: 770px) {
      padding-left: 0;
    }
    .ant-btn{
      border: none !important;
      margin-right: 5px;
    }

  }
  .condition-name {
    max-width: 100%;
    >span{
      line-height: 20px;
      display: inline;
    }
  }
  .tag{
    color: $primary-color;
    border-color: $primary-color;
    background: $primary-color-light;
    border-radius: 20px;
    padding: 0 10px;
    font-weight: 600;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .logic-list{
    .ant-space-horizontal{
      width: 100%;
      padding: 10px 0 0 0;
      justify-content: flex-end;
      @media only screen and (min-width: 770px) {
        width: auto;
        padding: 0;
        flex: 0 0  150px;
      }
    }
    .ant-list-item{
      display: flex;
      > .condition-name{

      }
    }
  }

  .mobile-menu-button{
    position: absolute;
    left: 0;
    top: 0;
    height: 65px;
    width: 65px;
    padding: 22px;
    @media only screen and (min-width: 770px) {
      display: none;
    }
    span{
      display: block;
      font-size: 20px;
      color: $primary-color;
    }
    .anticon-close{
      display: none;
    }

  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: $primary-color;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background: $primary-color;
    border-color: $primary-color;
  }
  .ant-radio-checked::after{
    border-color: $primary-color;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: $primary-color;
  }
  .ant-radio-inner::after{
    background: $primary-color;
  }
  .ant-radio-checked::after, .ant-radio-checked .ant-radio-inner{
    border-color: $primary-color;
  }

  .ant-btn{
    &:hover, &:focus{
      color: #fff !important;
      background: darken($primary-color, 10%) !important;
    }
  }

  .ant-btn-primary {
    background: $primary-color;
    border-color: $primary-color !important;
    border-radius: 4px;
    color: #fff;
    &:hover, &:focus{
      color: #fff !important;
      background: darken($primary-color, 10%) !important;
    }
  }

  .ant-select-selector:hover, .ant-select-selector:focus-within {
    border-color: $primary-color !important;
  }

  .ant-input-number:hover {
    border-color: $primary-color;
  }

  .ant-input:hover, .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus, .ant-input:focus,
  .ant-input-affix-wrapper-focused{
    border-color: $primary-color;
  }


  .ant-switch-checked {
    background: $primary-color;
  }

  .ant-btn-background-ghost.ant-btn-primary{
    color: $primary-color;
    border-color: $primary-color;
  }

  .ant-btn-dangerous{
    color: $error-color;
    border-color: $error-color !important;
  }

  .ant-btn{
    border: 1px solid $lightBorderColor;
    &.bg-success {
      background-color: #70C040;
      color: #fff;
      &.ant-btn:hover, &.ant-btn:focus{
        color: #fff;
      }
    }
  }

  .ant-btn:hover, .ant-btn:focus {
    color: $primary-color;
    border-color: $primary-color;
  }

  .page-header{
    margin-bottom: 10px;
    h3{
      margin: 0;
      margin-right: 10px;
    }
  }

  .risk-indicators{
    h5{
      margin: 0;
    }
    .ant-typography-secondary{
      margin-bottom: 10px;
      display: block;
    }
    .ant-list-bordered.ant-list-sm .ant-list-header, .ant-list-bordered.ant-list-sm .ant-list-footer {
      padding: 8px 14px;
    }
    .ant-list-bordered.ant-list-sm .ant-list-item {
      padding: 8px 12px;
    }
  }

  .ant-tabs-nav{
    border-bottom: 1px solid $lightBorderColor;
    .ant-tabs-tab{
      margin: 0 20px 0 0;
    }
  }

  .vtt-customAcordian{
    border: none ;
    background: none;
    .vtt-activeData-panel{
      border: 1px solid $greyBorder;
      margin-bottom: 10px;
      .ant-collapse{
        border: none;
      }
      .ant-collapse-item{
        border: none ;
        background: #fff;
        .ant-collapse-header{
          padding: 12px 10px;
          padding-right: 40px;
          background: $lightBgColor;
        }
      }
      .ant-collapse-content{
        background: #fff;
        > .ant-collapse-content-box {
          padding: 16px;
        }
      }
      .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding: 12px 16px;
        padding-right: 40px;
      }
      .ant-list-items{
        background: #fff;
      }
    }
  }

  .customAcordian{
    background: none;
    border: none;
    .ant-collapse{
      border: none;
    }
    .ant-collapse-item{
      border: none ;
      border-bottom: 1px solid $greyBorder;
      border-top: 1px solid $greyBorder;
      margin-bottom: -1px;
      background: #fff;
      .ant-collapse-header{
        padding: 12px 10px;
        padding-right: 40px;
      }
    }
    .ant-collapse-content{
      background: $lightBgColor;
      > .ant-collapse-content-box {
        padding: 16px;
      }
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding: 12px 16px;
      padding-right: 40px;
    }
    .ant-list-items{
      background: #fff;
    }

  }

  .ant-collapse-extra{
    width: 100%;
    padding: 10px 0;
    @media only screen and (min-width: 770px) {
      width: auto;
      padding: 0;
    }
  }

  .ant-collapse-content-box{
    .ant-list-bordered{
      margin-bottom: 10px;
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 13px;
    left: auto;
  }
  .ant-collapse-icon-position-right{
    > .ant-collapse-item > .ant-collapse-header{

    }
  }



  .main-menu-items{
    padding: 10px;
    .ant-btn{
      margin-bottom: 10px;
    }
  }

  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
    color: $primary-color;
  }

  .ant-menu-item{
    padding: 0 20px !important;
  }
  .ant-menu-item-selected {
    color: $primary-color;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected , .ant-menu-item:active, .ant-menu-submenu-title:active{
    background-color: $primary-color-light;
    color: $primary-color;
    font-weight: 600;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: $primary-color;
  }

  .ant-tabs-tab:hover {
    color: $primary-color;
  }

}

// global styles
.ant-popover-inner{
  .sketch-picker {
    border: 0 !important;
    box-shadow: none !important;
    padding: 5px 0 !important;
  }
}





